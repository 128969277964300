<template>
  <c-box mx="5" class="landing">
    <c-heading
      :fontSize="{ base: 'sm', lg: '2xl', xl: '4xl' }"
      as="h1"
      display="flex"
      alignItems="baseline"
      mb="4"
    >
      <c-text mr="3"> Growth Framework </c-text>
      <!-- <c-flex as="a" align="center">
        <c-text as="span" color="vc-orange.400" fontSize="md" mr="2">
          Share
        </c-text>
        <svg
          v-chakra="{
            w: '4',
            h: '4',
            fill: '#ef5323',
          }"
        >
          <use href="@/assets/icons/share-box-fill.svg#share"></use>
        </svg>
      </c-flex> -->
    </c-heading>
    <c-stack :spacing="10">
      <c-box
        borderRadius="8px"
        bg="#fff"
        boxShadow="0 3px 6px rgba(186, 186, 186, 0.16)"
        p="8"
        w="100%"
        minHeight="100px"
      >
        <c-heading as="h3" display="flex" fontSize="xl" mb="7">
          <c-text fontWeight="normal" mr="2"> Level of Business: </c-text>
          <c-text fontWeight="600">{{ companyLevel }}</c-text>
        </c-heading>
        <c-flex maxWidth="99%" align="center" h="80px">
          <ProgressBar
            :value="companyLevelPercentage"
            :height="'20px'"
            v-chakra="{
              flex: 1,
            }"
          />
          <svg
            v-chakra="{
              w: '10',
              h: '10',
              m: '-10px',
              zIndex: '1',
            }"
          >
            <use href="@/assets/icons/icon-target.svg#target"></use>
          </svg>
        </c-flex>
      </c-box>
      <c-box v-if="isFetchingTourData">
        <ContentLoader viewBox="0 0 250 130" primaryColor="#ddd">
          <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
          <rect x="0" y="20" rx="3" ry="3" width="250" height="10" />
          <rect x="0" y="40" rx="3" ry="3" width="250" height="10" />
          <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
      </c-box>
      <c-box v-else>
        <c-grid
          w="100%"
          :template-columns="{ base: '1fr', lg: `repeat(${tour.length}, 1fr)` }"
          :gap="{ base: 6, lg: 8, xl: 14 }"
        >
          <c-box
            borderRadius="8px"
            :bg="activeStage === stage.stage ? '#fff' : '#EEF0F2'"
            boxShadow="0 0px 6px rgba(186, 186, 186, 0.16)"
            p="8"
            w="100%"
            minHeight="100px"
            cursor="pointer"
            @click="selectStage(stage.stage)"
            v-for="stage in tour"
            :key="stage.id"
          >
            <c-flex align="center" justify="space-between">
              <c-text
                color="vc-orange.400"
                :fontSize="{ lg: 'base', xl: 'xl' }"
                fontWeight="500"
              >
                Stage {{ stage.stage }}
              </c-text>
              <c-text
                color="gray.400"
                :fontSize="{ lg: 'base', xl: 'xl' }"
                fontWeight="500"
              >
                {{ stage.time }}
              </c-text>
            </c-flex>
            <c-heading
              :fontSize="{ lg: 'xl', xl: '2xl' }"
              fontWeight="600"
              mt="2"
              mb="3"
            >
              {{ stage.title }}
            </c-heading>
            <c-list maxWidth="85%" mb="6">
              <c-list-item
                v-for="(description, index) in stage.descriptions"
                :key="index"
              >
                <c-flex mb="2">
                  <svg
                    v-chakra="{
                      w: '4',
                      h: '4',
                      mr: '2',
                    }"
                  >
                    <use href="@/assets/icons/icon-tools.svg#tools"></use>
                  </svg>
                  <c-text fontSize="xs" flex="1" color="gray.400">
                    {{ description }}
                  </c-text>
                </c-flex>
              </c-list-item>
            </c-list>
            <c-flex
              bg="#F8F8F8"
              borderRadius="70px"
              h="80px"
              :align="{ lg: 'normal', xl: 'center', xs: 'center' }"
              justify="space-between"
              px="6"
              flexWrap="wrap"
            >
              <c-pseudo-box
                as="a"
                display="flex"
                alignItems="center"
                cursor="pointer"
                @click="onStageStartClick(stage)"
              >
                <c-box
                  :w="{ lg: 8, xl: 10, xs: 5 }"
                  :h="{ lg: 8, xl: 10, xs: 5 }"
                >
                  <svg v-chakra w="100%" h="100%" mr="2">
                    <use
                      href="@/assets/icons/play-circle-fill.svg#play-circle"
                    ></use>
                  </svg>
                </c-box>
                <c-text
                  color="vc-orange.400"
                  :fontSize="{ lg: 'base', xl: 'lg' }"
                  fontWeight="600"
                >
                  Start
                </c-text>
              </c-pseudo-box>
              <c-box :w="{ lg: '100%', xl: '55%' }">
                <ProgressBar
                  :value="stageProgress(stage.stage).totalPercentage"
                  :valueOutside="true"
                  :height="'10px'"
                  v-chakra="{
                    w: '100%',
                  }"
                />
              </c-box>
            </c-flex>
          </c-box>
        </c-grid>
        <c-box
          borderRadius="8px"
          bg="#fff"
          boxShadow="0 3px 6px rgba(186, 186, 186, 0.16)"
          p="8"
          w="100%"
          minHeight="100px"
        >
          <c-grid
            w="100%"
            :template-columns="{ base: '1fr', lg: 'repeat(2, 1fr)' }"
            gap="16"
            v-if="activeStage !== 2"
          >
            <c-box
              v-for="(step, stepIndex) in activeStageData
                ? activeStageData.tour_steps
                : []"
              :key="step.target"
              mb="4"
            >
              <StageStep
                :step="step"
                @goToStep="
                  onGoToStep({ parentTour: activeStageData, stepIndex })
                "
                :stepIndex="stepIndex"
                :progress="stageProgress(activeStageData.stage)[step.key]"
              />
            </c-box>
          </c-grid>
          <c-stack :spacing="8" v-else>
            <c-box
              v-for="(page, pageIndex) in tour[1].tour_steps"
              :key="page.target"
              mb="4"
            >
              <StageStep
                :step="page"
                @goToStep="onGoToStep"
                @goToPage="onGoToPage($event, tour[1])"
                :stepIndex="pageIndex"
              />
            </c-box>
          </c-stack>
        </c-box>
      </c-box>
    </c-stack>
  </c-box>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import ProgressBar from '../components/ProgressBar.vue';
import StageStep from './components/home/StageStep.vue';

import { ContentLoader } from 'vue-content-loader';

export default {
  components: {
    ProgressBar,
    StageStep,
    ContentLoader,
  },
  data() {
    return {
      activeStage: null,
      isFetchingTourData: false,
    };
  },
  computed: {
    ...mapGetters('company', [
      'companyLevel',
      'companyLevelPercentage',
      'ideaStageLevel',
      'marketPlanLevel',
    ]),
    ...mapState('tour', {
      tour: (state) => state.tourStages,
    }),
    activeStageData() {
      return this.tour.find((stage) => stage.stage === this.activeStage);
    },
  },
  mounted() {
    this.selectStage(this.tour[0].stage);
  },
  methods: {
    ...mapActions({
      startTour: 'tour/startTour',
    }),
    selectStage(stage) {
      this.activeStage = stage;
    },
    onGoToStep({ parentTour, stepIndex = 0 }) {
      this.startTour({ tour: parentTour, startIndex: stepIndex });
    },
    onGoToPage(tourPage, tourGroup) {
      this.startTour({
        tour: tourPage.page,
        startIndex: 0,
        tourGroup,
      });
    },
    onStageStartClick(stage) {
      const isPageTour = stage.tour_steps.every((step) => step.type === 'PAGE');
      if (isPageTour) {
        this.startTour({
          tour: stage.tour_steps[0],
          startIndex: 0,
          tourGroup: stage,
        });
      } else {
        this.startTour({
          tour: stage,
          startIndex: 0,
        });
      }
    },
    stageProgress(stage) {
      switch (stage) {
        case 1:
          return this.ideaStageLevel;
        case 2:
          return { totalPercentage: this.companyLevelPercentage };
        case 3:
          return this.marketPlanLevel;
      }
    },
  },
};
</script>

<style></style>
